<template>
  <div class="container">
    <a-card shadow="never" class="my-card">
      <p style="display: flex; justify-content: space-between">
        <span style="color: lightgray">首页 / 签署服务协议</span>
        <a @click="$router.go(-1)">返回</a>
      </p>
      <p style="font-weight: bold; font-size: 20px; margin-top: 10px">
        签署服务协议
      </p>
      <a-steps :current="current" @change="onChange">
        <a-step title="主体资料录入" />
        <a-step title="信息核对" />
        <a-step title="入网进件审核" />
        <a-step title="审核结果" />
      </a-steps>
      <div class="msgBox">
        <p>恭喜您已完成全部注册流程</p>
        <a-button @click="$router.push('/')">立即换汇</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'sign-agreement-next',
  data() {
    return {
      current: 4,
    };
  },
  methods: {
    onChange(current) {
      this.current = current;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  box-sizing: border-box;
  padding: 24px;
  background: #f0f2f5;
  height: 100%;
  .my-card {
    height: 100%;
    margin-bottom: 20px;
    .msgBox {
      text-align: center;
      margin-top: 200px;
      & > p:first-child {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
